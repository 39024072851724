import React, { useState, useEffect, useContext } from "react";
import auditProtection from "./HOC/auditProtection";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'; // Import the CSS for styling


// Import components
import AuditHeaderForm from "./createAuditHeader.jsx";
import ProgramHeaderForm from "./create_program.jsx";
import CircularScore from "./CircularScore";
import CircularProgress from "./CircularProgress";


import "./styles.css";
import { ThemeProvider } from "styled-components";
import { ThemeContext } from "./theme.js";
import { StyledNavLink, STab } from "./styledComponents.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faExclamationTriangle,
  faRobot,
  faHomeLgAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
}

function AuditHomePage(props) {
  const { theme, setTheme } = useContext(ThemeContext);
  const [showAuditForm, setShowAuditForm] = useState(false);
  const [showProgramForm, setShowProgramForm] = useState(false);
  const [userID, setUserID] = useState("");
  const [checklists, setChecklists] = useState([]);
  const [auditPrograms, setAuditPrograms] = useState([]);
  const [audits, setAudits] = useState([]);
  const [activeTab, setActiveTab] = useState("auditPrograms");
  const [progress, setProgress] = useState({ total: 0, answered: 0 });
  const mToken = localStorage.getItem("MToken");
  const navigate = useNavigate();

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;
      setUserID(userID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const fetchAuditsByOrgFromToken = async () => {
    try {
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/audit/home"); // redirect to home if there is no MToken
        return;
      }

      // Decode the token to get membership data
      const response = await axios.post(
        `${apiUrl}/decode-membership-token/${mToken}`
      );
      const membershipData = response.data;

      if (
        membershipData &&
        membershipData.data &&
        membershipData.data.membership_data
      ) {
        const orgId = membershipData.data.membership_data.org_id; // Correctly access org_id
        //console.log("Organization ID:", orgId);

        // Fetch Audits using the org_id
        const auditsResponse = await axios.get(
          `${apiUrl}/audit/all-audits/${orgId}`
        );
        const auditsData = auditsResponse.data;
        //console.log("Audits by Org:", auditsData);

        // Update the audits state with the fetched data
        setAudits(auditsData);
      } else {
        console.error("Membership data not found in token");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAuditProgramsByOrg = async () => {
    try {
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/home"); // redirect to home if there is no MToken
        return;
      }

      // Decode the token to get membership data
      const response = await axios.post(
        `${apiUrl}/decode-membership-token/${mToken}`
      );
      const membershipData = response.data;

      if (membershipData && membershipData.data) {
        const orgId = membershipData.data.membership_data.org_id; // Correctly access org_id
        console.log("Organization ID:", orgId);

        // Fetch Audit Programs using the org_id
        const auditProgramsResponse = await axios.get(
          `${apiUrl}/audit/programs/${orgId}`
        );
        const auditProgramsData = auditProgramsResponse.data;
        console.log("Audit Programs:", auditProgramsData);

        // Update the auditPrograms state with the fetched data
        setAuditPrograms(auditProgramsData);
      } else {
        console.error("Membership data not found in token");
      }
    } catch (error) {
      console.error("Error fetching audit programs:", error);
    }
  };

  const fetchBlueprints = async (userID) => {
    try {
      const response = await axios.post(
        apiUrl + "/audit/user_blueprint_headers/",
        {
          user_id: userID,
        }
      );
      const userChecklists = response.data;
     
      setChecklists(userChecklists);
    } catch (error) {
      console.error("Error fetching checklists:", error);
    }
  };

  const renderAuditPrograms = () => {
    return auditPrograms.map((program) => (
      <div
        key={program.id}
        className="border p-4 rounded-lg shadow-md"
        onClick={() => navigate(`/audit/audit_program/${program.id}`)}
      >
        <div>
  <div className="flex justify-between items-center">
    <div className="flex items-center">
        <h3 className="text-xl font-semibold">{program.program_name}</h3>
        ...
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevent parent click event from firing
          
        }}
      >
        <FontAwesomeIcon icon={faPen} style={{ fontSize: '0.6em', color: 'grey' }} />
      </button>
    </div>
    <button className="ml-2 text-red-400"
    onClick={(e) => {
      e.stopPropagation(); // Prevent parent click event from firing
     
    }}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </div>
        <p>Start Date: {formatDate(program.start_date)}</p>
        <p>End Date: {formatDate(program.end_date)}</p>

        </div>
      </div>
    ));
  };

  const renderAudits = () => {
    return audits.map((audit) => (
      <div
        key={audit.id}
        className="border p-4 rounded-lg shadow-md"
        onClick={() => navigate(`/audit/create_audit/${audit.id}`)}
      >
   <div>
  <div className="flex justify-between items-center">
    <div className="flex items-center">
      <h3 className="text-xl font-semibold mr-1">{audit.audit_name}</h3>
      ...
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevent parent click event from firing
          handleEditAudit(audit.id, audit.audit_name, audit.date_scheduled, audit.auditor);
        }}
      >
        <FontAwesomeIcon icon={faPen} style={{ fontSize: '0.6em', color: 'grey' }} />
      </button>
    </div>
    <button className="ml-2 text-red-400"
    onClick={(e) => {
      e.stopPropagation(); // Prevent parent click event from firing
      handleDeleteAudit(audit.id);
    }}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </div>


        <p>Date Scheduled: {formatDate(audit.date_scheduled)}</p>
        {/* Assuming you might add date_completed in the future */}
        <p>
          Date Completed:{" "}
          {audit.date_completed ? formatDate(audit.date_completed) : "Pending"}
        </p>
        <p>Status: {audit.status}</p>
        <p>Auditor: {audit.auditor}</p>
        </div>
        <div className="flex justify-end">
  <div className="mr-1">
    <AuditScores auditId={audit.id} />
  </div>
  <div className="ml-1">
    <ProgressTracker auditId={audit.id} />
  </div>
</div>

      </div>
    ));
  };

  const renderBlueprints = () => {
    return checklists.map((checklist) => (
      <div
        key={checklist.id}
        onClick={() => navigate(`/audit/create_audit_blueprint/${checklist.id}`)}
        className="border p-4 rounded-lg shadow-md"
      >
        <div>
  <div className="flex justify-between items-center">
    <div className="flex items-center">
              <h3 className="text-xl font-semibold mr-1">{checklist.checklist_name}</h3>
              
              <button
               onClick={(e) => {
                 e.stopPropagation(); // Prevent parent click event from firing
                 handleEditChecklist(checklist.id, checklist.checklist_name);
               }}
              >..
              <FontAwesomeIcon icon={faPen} style={{ fontSize: '0.6em', color: 'grey' }} />
            </button> 
          </div>
          <button className="ml-2 text-red-400"
    onClick={(e) => {
      e.stopPropagation(); // Prevent parent click event from firing
      handleDeleteChecklist(checklist.id);
    }}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </div>
          <p>Date Created: {formatDate(checklist.created_at)}</p>
          <p>Organisation: {checklist.name}</p>
        </div>
  
        {/* Icons for actions */}
        <div className="flex justify-end mt-4">
        <div className="mr-2" onClick={(e) => e.stopPropagation()}>
            <button
              className="text-secondary"
              onClick={() => handleGenerateSmartAudit(checklist.id)}
            >
              <FontAwesomeIcon icon={faRobot} />
            </button>
          </div>
 
        </div>
      </div>
    ));
  }; 

  const AuditScores = ({ auditId }) => {
    const [score, setScore] = useState(null);
    const [unsafe, setUnsafe] = useState(false);
  
    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(`${apiUrl}/audit/scores/audit/${auditId}`);
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            console.error('Average score not found in the response');
            setScore(0);
          }
        } catch (error) {
          console.error('Error fetching score', error);
          setScore(0); // Or handle differently
        }
      };
  
      const checkUnsafe = async () => {
        try {
          const response = await axios.get(`${apiUrl}/audit/check_unsafe/${auditId}`);
          setUnsafe(response.data);
        } catch (error) {
          console.error('Error checking for unsafe entries', error);
        }
      };
  
      if (auditId) {
        fetchScore();
        checkUnsafe();
      }
    }, [auditId, apiUrl]);
  
    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }
  
    return (
<div className="circular-score-container" style={{ display: 'flex', alignItems: 'center' }}>
  {unsafe && (
    <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="unsafe-icon" style={{ marginRight: '8px', fontSize: '34px' }} />
  )}
  <CircularScore averageScore={score} />
</div>

    
    );
  };

  const ProgressTracker = ({ auditId }) => {
    const [progress, setProgress] = useState({ total: 0, answered: 0 });

    useEffect(() => {
      const fetchProgress = async () => {
        try {
          const response = await axios.get(apiUrl+`/audit/progress/${auditId}`); // Assuming this is your endpoint to fetch progress
          setProgress(response.data);
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      };

      fetchProgress();
    }, [auditId]);

    return (
      <CircularProgress total={progress.total} answered={progress.answered} />
    );
  };
  
  const hideChecklist = async (checklistId) => {
    try {
      await axios.put(`${apiUrl}/audit/hide_checklist/${checklistId}`);
      fetchBlueprints(userID); 
    } catch (error) {
      console.error("Error hiding checklist:", error);
      // Handle error appropriately
    }
  };
  
  const handleEditChecklist = async (checklistId, currentChecklistName) => {
    const { value: newName } = await Swal.fire({
        title: 'Edit Checklist Name',
        input: 'text',
        inputValue: currentChecklistName,
        inputPlaceholder: 'Enter new name',
        showCancelButton: true,
    });

    if (newName) {
        try {
            await axios.put(`${apiUrl}/audit/update_checklist_name/${checklistId}`, { checklist_name: newName });
            // Optionally handle success
            fetchBlueprints(userID);
        } catch (error) {
            console.error('Error updating checklist name:', error);
            // Optionally handle error
        }
    }
};

const handleEditAudit = async (auditId, currentAuditName, auditScheduled, auditor) => {
  try {
    // Fetch the program id for the current audit
    const response = await axios.get(`${apiUrl}/audit/selected_program/${auditId}`);
    const currentProgramId = response.data;
    console.log("Current Program ID:", response.data);

    // Generate the HTML template with SweetAlert
    const { value: formValues } = await Swal.fire({
      title: 'Edit Audit Details',
      html: `
      <input id="swal-input1" class="swal2-input" value="${currentAuditName}" placeholder="Audit Name" style="margin-bottom: 10px;">
      <input id="swal-input2" class="swal2-input" value="${auditScheduled}" placeholder="Scheduled Date" style="margin-bottom: 10px;">
      <input id="swal-input3" class="swal2-input" value="${auditor}" placeholder="Auditor" style="margin-bottom: 25px;">
      <select id="swal-input4" class="swal2-input" style="width: 57%; border: 1px solid #ccc;">
        ${auditPrograms.map(program => `<option value="${program.id}" ${program.id === currentProgramId ? 'selected' : ''}>${program.program_name}</option>`)}
      </select>`,
      focusConfirm: false,
      preConfirm: () => {
        return {
          auditName: document.getElementById('swal-input1').value,
          scheduledDate: document.getElementById('swal-input2').value,
          auditor: document.getElementById('swal-input3').value,
          selectedProgramId: document.getElementById('swal-input4').value // Get selected program ID
        };
      },
      didOpen: () => {
        // Initialize date picker for the scheduled date input
        const datePickerInput = document.getElementById('swal-input2');
        flatpickr(datePickerInput, {
          dateFormat: 'Y-m-d', // Change format if necessary
          enableTime: false, // Disable time selection
        });
      }
    });

    if (formValues) {
      try {
        // Convert the scheduled date to the correct format
        const scheduledDate = new Date(formValues.scheduledDate).toISOString();
    
        // Update audit details
        await axios.put(`${apiUrl}/audit/update_audit_details/${auditId}`, {
          audit_name: formValues.auditName,
          date_scheduled: scheduledDate,
          auditor: formValues.auditor
        });
    
        // Update program ID
        console.log("Selected Program ID:", formValues.selectedProgramId, "Audit ID:", auditId);
        await axios.post(`${apiUrl}/audit/update_program_detail`, {
          program_id: formValues.selectedProgramId, 
          audit_id: auditId
        });
    
        // Refresh audit list
        fetchAuditsByOrgFromToken();
      } catch (error) {
        console.error('Error editing audit:', error);
        // Optionally handle error
      }
    }
  } catch (error) {
    console.error('Error fetching program ID:', error);
    // Optionally handle error
  }
};

const handleDeleteChecklist = async (checklistId) => {
  try {
    const response = await axios.get(`${apiUrl}/audit/checklist_detail_exists/${checklistId}`);
    const hasDetails = response.data.hasDetails;
    
    let confirmationText = "";
    let confirmationButtonText = "";
    let confirmationAction = null;
    if (hasDetails) {
      confirmationText = "This checklist cannot be deleted because it is associated with audit details. We will hide it from view instead.";
      confirmationButtonText = "Hide";
      confirmationAction = hideChecklist;
    } else {
      confirmationText = "This checklist can be deleted. Are you sure you want to proceed?";
      confirmationButtonText = "Delete";
      confirmationAction = deleteChecklist;
    }

    Swal.fire({
      title: "Hold on a sec!",
      text: confirmationText,
      icon: "warning", // Use the warning icon
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: confirmationButtonText,
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      inputPlaceholder: `Type '${confirmationButtonText.toUpperCase()}' to confirm`,
      showLoaderOnConfirm: true,
      preConfirm: async (input) => {
        if (input !== confirmationButtonText.toUpperCase()) {
          Swal.showValidationMessage(
            `You need to type '${confirmationButtonText.toUpperCase()}' to confirm`
          );
        } else {
          await confirmationAction(checklistId);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  } catch (error) {
    console.error("Error handling checklist deletion:", error);
    // Handle error appropriately
  }
};

const handleDeleteAudit = async (auditId) => {
  try {
    const response = await axios.get(`${apiUrl}/audit/progress/${auditId}`);
    const answeredCount = response.data.answered;
    
    if (answeredCount === 0) {
      Swal.fire({
        title: "Hold on a sec!",
        text: "Are you sure you want to delete this audit?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "DELETE",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        inputPlaceholder: "Type 'DELETE' to confirm",
        showLoaderOnConfirm: true,
        preConfirm: async (input) => {
          if (input !== "DELETE") {
            Swal.showValidationMessage("You need to type 'DELETE' to confirm");
          } else {
            try {
              await deleteAudit(auditId);
              // If deletion is successful, you may want to perform any additional actions or update UI accordingly
            } catch (error) {
              console.error("Error deleting audit:", error);
              // Handle error appropriately
            }
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    } else {
      Swal.fire({
        title: `This audit cannot be deleted because it has been started.`,
        icon: "warning",
        showCancelButton: false,
        cancelButtonColor: "#3085d6",
        cancelButtonText: "OK",
      });
    }
  } catch (error) {
    console.error("Error handling audit deletion:", error);
    // Handle error appropriately
  }
};

const deleteChecklist = async (checklistId) => {
  try {
    await axios.delete(`${apiUrl}/audit/delete_checklist/${checklistId}`);
    fetchBlueprints(userID);
  } catch (error) {
    console.error("Error deleting checklist:", error);
    // Handle error appropriately
  }
};

const deleteAudit = async (auditId) => {
  try {
    await axios.delete(`${apiUrl}/audit/delete_audit/${auditId}`);
    fetchAuditsByOrgFromToken();
  } catch (error) {
    console.error("Error deleting checklist:", error);
    // Handle error appropriately
  }
};

  const handleGenerateSmartAudit = async (checklistId) => {
    Swal.fire({
      title: "Get ready for some AI magic!",
      text: "Use past audit performance stats to generate a smarter checklist?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#4CAF50",
      confirmButtonText: "Yiha let's go!",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Nah",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Generating Smart Audit Checklist!",
          "Sit back and relax while our AI does its thing....coming soon!",
          "success"
        );
        // Call function to generate the smart audit here
      }
    });
  };

  const handleCreateAudit = () => {
    // Toggle the audit form visibility
    setShowAuditForm(!showAuditForm);
  };

  const handleCreateProgram = () => {
    // Toggle the audit form visibility
    setShowProgramForm(!showProgramForm);
  };

  useEffect(() => {
    fetchUserID();
    fetchBlueprints(userID);
  }, [userID]);

  useEffect(() => {
    if (userID) {
      if (activeTab === "My Blueprints") {
        fetchBlueprints(userID);
      } else if (activeTab === "auditPrograms") {
        fetchAuditProgramsByOrg();
      } else if (activeTab === "audits") {
        fetchAuditsByOrgFromToken();
      }
    }
  }, [userID, activeTab]);

  //Main render

  return (
    <ThemeProvider theme={theme}>
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between mt-5 mb-10">
          <div
            onClick={() => navigate("/home")}
            className="text-primary cursor-pointer flex-shrink-0"
          >
            {theme.logoUrl ? (
              <img
                src={theme.logoUrl}
                alt="Membership Logo"
                className="h-16 w-auto"
              />
            ) : (
              <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
            )}
          </div>
          <h1 className="text-4xl text-primary flex-grow text-center">
            Audit Management
          </h1>
          <div className="w-20"> {/* Placeholder to balance the header */}</div>
        </div>

        {/* Main content area */}
        <div className="flex">
          {/* Sidebar for Manage Audits */}
          <div className="w-1/5">
            {" "}
            {/* Adjust width as needed */}
            <div className="flex flex-col items-start">
              <StyledNavLink
                primary
                theme={theme}
                onClick={handleCreateProgram}
                className="w-2/3 mb-2 py-2 px-4 rounded text-center"
              >
                New Program
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                onClick={handleCreateAudit}
                className="w-2/3 mb-2 py-2 px-4 rounded text-center"
              >
                New Audit
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                to="/audit/createChecklistHeader"
                className="w-2/3 mb-2 py-2 px-4 rounded text-center"
              >
                New BluePrint
              </StyledNavLink>
              {/* Render the AuditHeaderForm here based on showAuditForm state */}
              {showAuditForm && <AuditHeaderForm />}
              {showProgramForm && <ProgramHeaderForm />}
            </div>
          </div>

          {/* Tab Navigation and Content */}
          <div className="w-4/5 pl-4">
            {" "}
            {/* Adjust width as needed */}
            <div className="flex mb-4">
              <STab
                className={activeTab === "auditPrograms" ? "active" : ""}
                onClick={() => setActiveTab("auditPrograms")}
              >
                Programs
              </STab>
              <STab
                className={activeTab === "audits" ? "active" : ""}
                onClick={() => setActiveTab("audits")}
              >
                Audits
              </STab>
              <STab
                className={activeTab === "My Blueprints" ? "active" : ""}
                onClick={() => setActiveTab("My Blueprints")}
              >
                My Blueprints
              </STab>
            </div>
            {/* Tab Content */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {activeTab === "My Blueprints" && renderBlueprints()}
              {activeTab === "auditPrograms" && renderAuditPrograms()}
              {activeTab === "audits" && renderAudits()}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default auditProtection(AuditHomePage);
