import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import superAdminProtection from './HOC/superAdminProtection';
import { StyledNavLink } from "./styledComponents.js";
import { ThemeContext } from "./theme.js";
import { ThemeProvider } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faHomeLgAlt } from "@fortawesome/free-solid-svg-icons";

function AdminPage(props) {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div className="container mx-auto px-6">
    
    <div className="flex items-center mt-5">
      <Link to="/home" className="text-primary">
        <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" /> {/* Adjust the size as needed */}
      </Link>
    </div>

      <h1 className="text-4xl mb-10 text-center text-primary">
        Administrator Dashboard
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {/* Admin Menu Options */}
        <div>
          <h2 className="text-2xl text-center mb-5">Manage Profiles</h2>

          <StyledNavLink
            primary
            theme={theme}
            to="/register"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            Register Global Profile
          </StyledNavLink>

          <StyledNavLink
            primary
            theme={theme}
            to="/admin/all_global_profiles"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            List Global Profiles
          </StyledNavLink>
        </div>
        <div>
          <h2 className="text-2xl text-center">Manage Organisations</h2>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/create_org"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            Add New Organisation
          </StyledNavLink>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/add_org_function"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            Add/View Organisation Functions
          </StyledNavLink>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/edit_org"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            Update Organisation
          </StyledNavLink>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/list_all_organisations"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            List Organisations
          </StyledNavLink>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/org_active_status"
            className="bg-red-300 w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-red-500"
          >
            Deactivate Organisation
          </StyledNavLink>
        </div>
        <div>
          <h2 className="text-2xl text-center">Membership</h2>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/add_membership"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            Add Membership
          </StyledNavLink>
        </div>
        <div>
          <h2 className="text-2xl text-center">Audits</h2>
          <StyledNavLink
            primary
            theme={theme}
            to="/admin/audit_dashboard"
            className="bg-primary w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center hover:bg-secondary"
          >
            Audit Dashboard
          </StyledNavLink>
        </div>
      </div>
    </div>

  );
}

export default superAdminProtection(AdminPage);
