import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

export default function AddOrganisationFunction() {
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [allFunctions, setAllFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [orgFunctions, setOrgFunctions] = useState([]);
  const [remainingFunctions, setRemainingFunctions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // To control the modal
  const [alias, setAlias] = useState(""); // To hold the input from the modal
  const [transferredFunctions, setTransferredFunctions] = useState([]); // To hold the transferred functions
  const [functionId, setFunctionId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const orgRes = await axios.get(apiUrl + "/all_organisations");
      setOrganisations(orgRes.data);

      const funcRes = await axios.get(apiUrl + "/all_functions");
      setAllFunctions(funcRes.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedOrg) {
      fetchOrgFunctions(selectedOrg.id);
    }
  }, [selectedOrg]);

  const handleFunctionSelect = (id) => {
    setSelectedFunction(id);
  };

  const handleTransfer = () => {
    if (!selectedFunction) return;

    setRemainingFunctions((remaining) =>
      remaining.filter((func) => func.id !== selectedFunction)
    );

    setOrgFunctions((org) => [...org, selectedFunction]);

    setSelectedFunction(null);
  };

  const fetchOrgFunctions = async (orgId) => {
    const res = await axios.get(`${apiUrl}/organisation_functions/${orgId}`);
    console.log("Organisation Functions:", res.data);
    setOrgFunctions(res.data);
    return res.data;
  };

  const handleOrgSelect = (e) => {
    const selected = organisations.find((org) => org.id == e.target.value);
    setSelectedOrg(selected);

    fetchOrgFunctions(selected.id).then((orgFuncs) => {
      if (orgFuncs) {
        setOrgFunctions(orgFuncs);
        const newRemainingFunctions = allFunctions.filter(
          (func) => !orgFuncs.includes(func.id)
        );
        setRemainingFunctions(newRemainingFunctions);
      }
    });
  };

  const handleTransferFunction = () => {
    if (selectedFunction) {
      const selectedFunctionData = remainingFunctions.find((func) => func.id === selectedFunction)
      if (selectedFunctionData) {
        setAlias(selectedFunctionData.name);
      }
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedFunction(null);
  };

  const handleModalSubmit = async () => {
    try {
      const response = await handleAddOrganisationFunction(
        selectedOrg.id,
        selectedFunction,
        alias
      );
      console.log(
        "Adding Organisation Function:",
        selectedOrg.id,
        selectedFunction,
        alias
      );

      // Remove the transferred function from remainingFunctions
      setRemainingFunctions((prevVal) =>
        prevVal.filter((func) => func.id !== selectedFunction)
      );

      // Clear alias textbox
      setAlias("");
    } catch (err) {
      console.error("Failed in handleAddOrganisationFunction:", err);
    }

    // Close the modal
    handleModalClose();
  };

  const handleAddOrganisationFunction = async () => {
    console.log("Adding Organisation Function:", {
      org_id: selectedOrg && selectedOrg.id,
      function_id: selectedFunction,
      alias: alias,
      is_active: isActive, // or true/false if isActive is not defined
    });

    if (selectedOrg && selectedFunction) {
      try {
        const response = await axios.post(`${apiUrl}/organisation-functions`, {
          org_id: selectedOrg.id,
          function_id: selectedFunction,
          alias: alias,
          is_active: isActive, // or true/false if isActive is not defined
        });
        console.log(response.data);
        // concurrently update your state to reflect changes
        setOrgFunctions((prev) => [...prev, selectedFunction]);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please select an organization and transfer at least one function");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-primary">
      <h2 className="text-4xl text-center mb-10">Organisation & Functions</h2>
      <div className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white text-primary shadow-xl rounded-lg">
        <div>
          <label className="text-lg mb-2">Organisation:</label>
          <select
            value={selectedOrg ? selectedOrg.id : ""}
            onChange={handleOrgSelect}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="" disabled>
              Select an organisation...
            </option>
            {organisations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          {remainingFunctions.length > 0 ? (
            <>
              <label className="text-lg mb-2">Remaining Functions:</label>
              {remainingFunctions.map((func) => (
                <div
                  key={func.id}
                  className={`border h-10 border-gray-300 px-3 rounded my-2 ${
                    selectedFunction === func.id ? "bg-primary2" : ""
                  }`}
                  onClick={() => handleFunctionSelect(func.id)}
                >
                  {func.name}
                </div>
              ))}
            </>
          ) : (
            <label className="text-lg mb-2">Remaining Function: None</label>
          )}
        
        </div>
        <div>
        <div className="flex justify-center">
          <button
            className="bg-primary text-white p-2 rounded mt-4"
            onClick={handleTransferFunction}
          >
            Add Selected Function
          </button>
          </div>
        </div>
        <div>
          <label className="text-lg mb-2">Organisation Functions:</label>
          {orgFunctions.map((funcId) => {
            const correspondingFunction = allFunctions.find(
              (f) => f.id === funcId
            );
            return (
              <div
                key={funcId}
                className="border h-10 border-gray-300 px-3 rounded my-2"
              >
                {correspondingFunction?.name ||
                  `No Name for function with id: ${funcId}`}
              </div>
            );
          })}
        </div>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "2rem",
          },
        }}
        contentLabel="Function Transfer Confirmation"
      >
        <div className="flex flex-col items-center bg-white text-gray-400 rounded">
          <h2 className="text-2xl mb-4 text-center">Function Alias</h2>
          <p className="text-center mb-4">
            Change the text below if you would like to set an alias.
          </p>
          <input
            type="text"
            className="border h-10 border-gray-300 px-3 rounded my-2"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            placeholder="Enter alias here (optional)"
          />
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
            onClick={handleModalSubmit}
          >
            Continue
          </button>
        </div>
      </ReactModal>
    </div>
  );
}