const CircularProgress = ({ total, answered }) => {
  const radius = 18; // Fixed radius
  const circumference = 2 * Math.PI * radius;
  const progress = (answered / total) * circumference;

  // Determine the font size based on the length of the text
  const textLength = `${answered}/${total}`.length;
  let fontSize;
  if (textLength <= 4) {
      fontSize = '12px'; // Larger font for 1-4 characters
  } else if (textLength <= 6) {
      fontSize = '10px'; // Medium font for 5-6 characters
  } else {
      fontSize = '8px'; // Smaller font for 7+ characters
  }

  return (
    <svg width="40" height="40" className="circular-progress">
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="none"
        stroke="#ddd"
        strokeWidth="4"
      />
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="none"
        stroke={answered === total ? "#10B981" : "#007bff"}
        strokeWidth="4"
        strokeDasharray={`${progress} ${circumference}`}
        transform="rotate(-90 20 20)"
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fill={answered === total ? "#10B981" : "#007bff"}
        style={{ fontSize: fontSize }}
      >
        {`${answered}/${total}`}
      </text>
    </svg>
  );
};

export default CircularProgress;
