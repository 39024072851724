import React from 'react';

const CircularScore = ({ averageScore }) => {
  const radius = 18; // Fixed radius
  const circumference = 2 * Math.PI * radius;
  
  // Calculate progress
  let progress = averageScore >= 100 ? circumference : (averageScore / 100) * circumference;

  // Determine the color based on the score
  let strokeColor;
  if (averageScore >= 100) {
    strokeColor = '#008000'; // Dark Green
  } else if (averageScore >= 80) {
    strokeColor = '#00CD00'; // Light Green
  } else if (averageScore >= 40) {
    strokeColor = '#FFA500'; // Orange
  } else {
    strokeColor = '#FF0000'; // Red
  }

  
  const formattedScore = averageScore.toFixed(0);

  // Determine the font size based on the length of the text
  const textLength = `${formattedScore}`.length;
  let fontSize;
  if (textLength <= 4) {
      fontSize = '12px'; // Larger font for 1-4 characters
  } else if (textLength <= 6) {
      fontSize = '10px'; // Medium font for 5-6 characters
  } else {
      fontSize = '8px'; // Smaller font for 7+ characters
  }

 

  return (
    <svg width="40" height="40" className="circular-progress">
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="none"
        stroke="#ddd"
        strokeWidth="4"
      />
      <circle
        cx="20"
        cy="20"
        r={radius}
        fill="none"
        stroke={strokeColor}
        strokeWidth="4"
        strokeDasharray={`${progress} ${circumference}`}
        transform="rotate(-90 20 20)"
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fill={strokeColor}
        style={{ fontSize: fontSize }}
      >
        {formattedScore}
      </text>
    </svg>
  );
};

export default CircularScore;
