import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactModal from "react-modal";

const apiUrl = process.env.REACT_APP_API_URL;

const withMembershipProtection = (Component) => {
  return (props) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasMembership, setHasMembership] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const mToken = localStorage.getItem("MToken");
          if (!mToken) {
            navigate("/home"); // redirect to home if there is no MToken
            return;
          }

          const response = await axios.post(
            `${apiUrl}/decode-membership-token/${mToken}`
          );
          const membershipData = response.data; // Access membership_data
          console.log("Membership Data:", membershipData.data);

          // Check if the user has the desired membership(s)
          const desiredMemberships = [
            "ddd7550a-57e6-4bee-9481-b85869c645e5",  // Safety Department
            "363d352b-a408-41a4-b3eb-1e5639879b0d",  // Compliance Monitoring
          ];

          const userMemberships = membershipData.data.membership_data.memberships;

          const hasDesiredMembership = userMemberships.some((membership) =>
            desiredMemberships.includes(membership.function)
          );

          setHasMembership(hasDesiredMembership);

          console.log("hasDesiredMembership:", hasDesiredMembership);
         

          if (!hasDesiredMembership) {
            setIsModalOpen(true);
          }
        } catch (err) {
          setIsModalOpen(true);
        }
      };
      fetchData();
    }, [navigate]);

    if (!hasMembership) {
      return (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "2rem",
            },
          }}
          contentLabel="Access Denied Modal"
        >
          <div className="flex flex-col items-center bg-white text-gray-400 rounded">
            <h2 className="text-2xl mb-4 text-center">Not Authorized</h2>
            <p className="text-center">
              This area is not available to your account
            </p>
            <button
              onClick={() => {
                setIsModalOpen(false);
                navigate("/home");
              }}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </ReactModal>
      );
    }
    // If the user has the desired membership, render the wrapped component
    return <Component {...props} />;
  };
};

export default withMembershipProtection;