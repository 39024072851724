import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "./login_logo.png";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import "./styles.css";
import { ThemeContext, defaultTheme } from "./theme.js";

const apiUrl = process.env.REACT_APP_API_URL;

const Login =  () => {
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setTheme } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  
  useEffect(() => {
    // Clear the cached theme from local storage and reset to default on component mount
    localStorage.removeItem("theme");
    localStorage.removeItem("MToken");
    setTheme(defaultTheme);
  }, [setTheme]); // On
  

  const login = async () => {
    const data = new URLSearchParams();
    data.append("username", username);
    data.append("password", password);

    try {
      let response = await axios({
        method: "post",
        url: apiUrl + "/login",
        data: data.toString(),
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });

      localStorage.setItem("access_token", response.data.access_token);

      // Check if the user is active.
      let activeStatusResponse = await axios.get(
        apiUrl + "/check_active_status",
        {
          headers: { Authorization: `Bearer ${response.data.access_token}` },
        }
      );

      // if user is active navigate to home else navigate to /inactive
      if (activeStatusResponse.data.is_active) {
        navigate("/home");
      } else {
        navigate("/inactive");
      }
    } catch (error) {
      console.log("There was an error with the login!", error.response);
      if (error.response && error.response.status === 401) {
        setIsModalOpen(true);
      } else {
        navigate("/login");
      }
    }
  };

  return (
    <form onSubmit={(event) => {event.preventDefault(); login();}}>
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex justify-start">
        <img
          src={Logo}
          alt="Logo"
          className="navbar-item logo-image-size mb-8"
        />
      </div>

      
      <div className="flex flex-col w-1/5">
        
        <div
          className="flex border-2 rounded px-4 py-2 mb-4 items-center"
          onChange={(e) => setUsername(e.target.value)}
        >
          <input
            className="flex-1 outline-none text-center"
            type="text"
            placeholder="Username"
          />
          <span>
            <i className="fas fa-user text-gray-400"></i>
          </span>
        </div>

       
        <div
          className="flex border-2 rounded px-4 py-2 mb-4 items-center"
          onChange={(e) => setPassword(e.target.value)}
        >
          <input
            className="flex-1 outline-none text-center"
            type="password"
            placeholder="Password"
          />
          <span>
            <i className="fas fa-key text-gray-400"></i>
          </span>
        </div>
        <div className="flex justify-center mt-6">
       
      <button
          type="submit"
          className="bg-primary hover:bg-secondary text-white font-bold py-2 px-16 rounded-full"
        >
          Login
      </button>
   
        </div>
        <div className="mt-4 text-center">
          <Link className="text-xs text-primary" to="/register">
            Don't have an account? Register now
          </Link>
        </div>
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "2rem",
            },
          }}
          contentLabel="Access Denied Modal"
        >
          <div className="flex flex-col items-center bg-white text-gray-400 rounded">
            <h2 className="text-2xl mb-4 text-center">Login Failed</h2>
            <p className="text-center">Username or password incorrect</p>
          </div>
        </ReactModal>
      </div>
    </div>
    </form>
  );
};

export default Login;
