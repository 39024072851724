import superAdminProtection from "./HOC/superAdminProtection";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const EditOrganisationStatus = () => {
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetchOrganisations();
  }, []);

  const fetchOrganisations = async () => {
    const res = await axios.get(apiUrl + "/all_organisations");
    setOrganisations(res.data);
  };

  const handleOrgSelect = (e) => {
    console.log("handleOrgSelect triggered"); // Debugging statement
    const selected = organisations.find((org) => org.id == e.target.value);
    setSelectedOrg(selected);
    console.log(selected); // debugging
  };

  const handleCheckboxChange = async (e) => {
    if (selectedOrg) {
      // Store new value
      const newIsActiveValue = e.target.checked;
    
      await axios.patch(
        `${apiUrl}/organisations/${selectedOrg.id}/is_active?is_active=${newIsActiveValue}`
      );
  
      // Once the axios call is completed, update the state
      setSelectedOrg(prevSelectedOrg => ({
        ...prevSelectedOrg, 
        is_active: newIsActiveValue
      }));
  
      // Wait for the state update and then fetch organisations
      await fetchOrganisations();
    }
  };

  return (
<div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-red-600">
  <h2 className="text-4xl text-center mb-10">
    Organisation Active Status
  </h2>
  <div className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white text-primary shadow-xl rounded-lg">
    <div>
      <label className="text-lg mb-2">Select an organisation:</label>
      <select onChange={handleOrgSelect} value={selectedOrg?.id || ""} className="w-full border h-10 border-gray-300 px-3 rounded">
        <option value="" disabled>
          Select an organisation...
        </option>
        {organisations.map((org) => (
          <option key={org.id} value={org.id}>
            {org.name}
          </option>
        ))}
      </select>
    </div>
    {selectedOrg && (
      <div>
        <label className="text-lg mb-2">Is Active</label>
<div className="flex items-center">
  <input
    id="is_active"
    type="checkbox"
    checked={selectedOrg.is_active}
    onChange={handleCheckboxChange}
    className="h-5 w-5 text-blue-600 rounded"
  />
  <label htmlFor="is_active" className="ml-2 text-gray-700">
    {selectedOrg.is_active ? "Active" : "Inactive"}
  </label>
  </div>
  <button
      variant="contained" 
      color="primary" 
      onClick={() => navigate("/admin")} 
      className="bg-primary hover:bg-secondary text-white font-bold py-2 px-12 rounded-full mt-4 block mx-auto"
    >
      OK
    </button>
</div>

)}
  
  </div>




</div>
    );
};

export default superAdminProtection(EditOrganisationStatus);
