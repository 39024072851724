import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar, Bubble } from "react-chartjs-2";
import "chart.js/auto";

const Dashboard = () => {
  const [orgScores, setOrgScores] = useState([]);
  const [auditScores, setAuditScores] = useState([]);
  const [checklistScores, setChecklistScores] = useState([]);
  const [selectedTitleType, setSelectedTitleType] = useState("regulation_title");
  const [regulationScores, setRegulationScores] = useState([]);
  const [showParentTitle, setShowParentTitle] = useState(false);
  

  // Define your API URL
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchChecklistScores = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/audit/scores/checklist`
        );
        // Process and set the state with the fetched checklist scores
        setChecklistScores(response.data);
        console.log("response.data (checklist scores):", response.data);
      } catch (error) {
        console.error("Error fetching checklist scores", error);
        // Handle error as needed
      }
    };

    fetchChecklistScores();
  }, [apiUrl]);

  useEffect(() => {
    const fetchAuditScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/audit`);
        // Process and set the state with the fetched audit scores
        setAuditScores(response.data);
        console.log("response.data (audit scores):", response.data);
      } catch (error) {
        console.error("Error fetching audit scores", error);
        // Handle error as needed
      }
    };

    fetchAuditScores();
  }, [apiUrl]);

  useEffect(() => {
    const fetchOrganizationScores = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/audit/scores/organization`
        );
        // Process and set the state with the fetched data
        setOrgScores(response.data);
      } catch (error) {
        console.error("Error fetching organization scores", error);
        // Handle error as needed
      }
    };
    fetchOrganizationScores();
  }, [apiUrl]); // apiUrl as dependency, if it's constant, you can omit it from the dependency array

  // Bar chart data and options
  const barData = {
    labels: orgScores.map((score) => score.org_name),
    datasets: [
      {
        label: "Average Score",
        data: orgScores.map((score) => score.average_score),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const checklistBarData = {
    labels: checklistScores.map((score) => score.checklist_name),
    datasets: [
      {
        label: "Average Score",
        data: checklistScores.map((score) => score.average_score),
        backgroundColor: "rgba(255, 99, 71, 0.2)", // Customize the colors as needed
        borderColor: "rgba(255, 99, 71, 1)",
        borderWidth: 1,
      },
    ],
  };

  const checklistBarOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const auditBarData = {
    labels: auditScores.map((score) => score.audit_name),
    datasets: [
      {
        label: "Average Score",
        data: auditScores.map((score) => score.average_score),
        backgroundColor: "rgba(255, 99, 132, 0.2)", // Customize the colors as needed
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const auditBarOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const fetchRegulationScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/regulation`);
        // Process and set the state with the fetched regulation scores
        setRegulationScores(response.data);
        console.log("response.data (regulation scores):", response.data);
      } catch (error) {
        console.error("Error fetching regulation scores", error);
        // Handle error as needed
      }
    };

    fetchRegulationScores();
  }, [apiUrl]);

  useEffect(() => {
    const fetchAuditScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/audit`);
        // Process and set the state with the fetched audit scores
        setAuditScores(response.data);
        console.log("response.data (audit scores):", response.data);
      } catch (error) {
        console.error("Error fetching audit scores", error);
        // Handle error as needed
      }
    };

    fetchAuditScores();
  }, [apiUrl]);

  const toggleTitleType = () => {
    // Toggle between "regulation_title" and "parent_regulation_title"
    setShowParentTitle(!showParentTitle);
    setSelectedTitleType(showParentTitle ? "regulation_title" : "parent_regulation_title");
  };

  const getChartData = () => {
    if (selectedTitleType === "regulation_title") {
      // Use "regulation_title" for labels
      return {
        labels: regulationScores.map((score) => score.regulation_title),
        datasets: [
          {
            label: "Scores",
            data: regulationScores.map((score) => score.average_score),
          },
        ],
      };
    } else {
      // Use "parent_regulation_title" for labels
      // Perform data manipulation to group by parent title and calculate average scores
      const groupedData = regulationScores.reduce((result, score) => {
        const parentTitle = score.parent_regulation_title;
        if (!result[parentTitle]) {
          result[parentTitle] = [];
        }
        result[parentTitle].push(score.average_score);
        return result;
      }, {});

      const labels = Object.keys(groupedData);
      const data = labels.map((parentTitle) => groupedData[parentTitle]);

      return {
        labels,
        datasets: [
          {
            label: "Scores",
            data,
          },
        ],
      };
    }
  };

  const renderTable = () => {
    const data = getChartData();
    const titleKey = showParentTitle ? "parent_regulation_title" : "regulation_title";

    // Filter out rows with zero or blank scores
    const filteredData = data.labels.reduce((result, title, index) => {
      const score = data.datasets[0].data[index];
      if (score !== 0 && !isNaN(score)) {
        result.labels.push(title);
        result.scores.push(score);
      }
      return result;
    }, { labels: [], scores: [] });

    // Sort the filtered data by average score in ascending order
    const sortedData = filteredData.labels.map((title, index) => ({
      title,
      score: filteredData.scores[index],
    })).sort((a, b) => a.score - b.score);

    return (
        <div>
          <table style={{ width: "55%" }}> {/* Set a fixed width or percentage value */}
            <thead>
              <tr>
                <th style={{ width: "50%" }}> {/* Adjust the width of columns */}
                  {showParentTitle ? "Parent Regulation" : "Regulation"}
                </th>
                <th style={{ width: "5%" }}> {/* Adjust the width of columns */}
                  Average Score
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor:
                      item.score >= 80
                        ? "green"
                        : item.score >= 40
                        ? "orange"
                        : "pink",
                  }}
                >
                  <td>{item.title}</td>
                  <td
                style={{
                  textAlign: "center", // Center-align the content
                  fontWeight: "bold", // Make the text bold
                }}
              >
                {item.score}
              </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    return (
        <div className="h-screen flex flex-col">
          <h2 className="mb-2 text-2xl font-bold">Global Scores Dashboard</h2>
      
          {/* Chart container with flex */}
          <div className="flex">
            {/* Organization Scores Bar Chart */}
            <div className="w-1/3 p-4">
              <h2>Organization Scores</h2>
              {orgScores.length > 0 && <Bar data={barData} options={barOptions} />}
            </div>
      
            {/* Audit Scores Bar Chart */}
            <div className="w-1/3 p-4">
              <h2>Audit Scores</h2>
              {auditScores.length > 0 && (
                <Bar data={auditBarData} options={barOptions} />
              )}
            </div>
      
            {/* Checklist Scores Bar Chart */}
            <div className="w-1/3 p-4">
              <h2>Checklist Scores</h2>
              {checklistScores.length > 0 && (
                <Bar data={checklistBarData} options={checklistBarOptions} />
              )}
            </div>
          </div>
      
          {/* Toggle Button */}
          <div className="w-1/3 p-4">
            <button
              onClick={toggleTitleType}
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            >
              Show {showParentTitle ? "Regulation Title" : "Parent Title"}
            </button>
          </div>
      
          {/* Render the table */}
          {renderTable()}
        </div>
      );
      
      
};

export default Dashboard;
