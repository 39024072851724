import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./login";
import Admin from "./admin";
import Register from "./register"; 
import Profiles from './all_global_profiles';
import CreateOrg from './create_organisation';
import Status from './status';
import Inactive from "./inactive";
import Home from "./home";
import List_Organisations from "./list_all_organisations";
import EditOrganisation from "./edit_org";
import EditOrganisationStatus from "./org_active_status";
import AddOrganisationFunction from "./add_org_function";
import CreateMembership from "./add_membership";
import AuditHeaderForm from "./createAuditHeader";
import ChecklistHeaderForm from "./createChecklistHeader";
import AuditChecklistComponent from "./create_audit_checklist.jsx";
import AuditHomePage from "./home_audit.jsx";
import CreateProgramPage from "./create_program.jsx";
import AuditProgram from "./home_audit_program.jsx";
import CreateAuditPage from "./create_audit.jsx";
import AuditContentLive from "./auditLive.jsx";
import Dashboard from "./auditScoresDashboard.jsx"
import MonthDetailPage from "./auditMonth.jsx"; 
import { ThemeProvider } from './theme.js'; 



function App() {
  return (
    <ThemeProvider> {/* Wrap Routes with ThemeProvider */}
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin/all_global_profiles" element={<Profiles />} />
        <Route path="/admin/create_org" element={<CreateOrg />} />
        <Route path="/" element={<Login />} />
        <Route path="/status" element={<Status />} />
        <Route path="/inactive" element={<Inactive />} />
        <Route path="/home" element={<Home />} />
        <Route path="/admin/list_all_organisations" element={<List_Organisations />} />
        <Route path="/admin/edit_org" element={<EditOrganisation />} />
        <Route path="/admin/org_active_status" element={<EditOrganisationStatus />} />
        <Route path="/admin/add_org_function" element={<AddOrganisationFunction />} />
        <Route path="/admin/add_membership" element={<CreateMembership />} />
        <Route path="/admin/audit_dashboard" element={<Dashboard />} />/
        <Route path="/audit/home" element={<AuditHomePage />} />
        <Route path="/audit/month-detail/:year/:month/:programId" element={<MonthDetailPage />} /> 
        <Route path="/audit/create_program/:programId" element={<CreateProgramPage />} />
        <Route path="/audit/audit_program/:programId" element={<AuditProgram />} />
        <Route path="/audit/createAuditHeader" element={<AuditHeaderForm />} />
        <Route path="/audit/createChecklistHeader" element={<ChecklistHeaderForm />} />
        <Route path="/audit/create_audit_blueprint/:checklistId" element={<AuditChecklistComponent />} />
        <Route path="/audit/create_audit/:auditId" element={<CreateAuditPage />} />
        <Route path="/audit/audit_live/:auditId" element={<AuditContentLive />} />
        
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;