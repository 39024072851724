import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const apiUrl = process.env.REACT_APP_API_URL;


const AuditProgramForm = () => {
  const navigate = useNavigate();

  const [auditProgramHeader, setAuditProgramHeader] = useState({
    program_name: "",
    org_id: "", // Removed from state
    start_date: "",
    end_date: "",
    is_active: false,
  });

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [programDuration, setProgramDuration] = useState(1);

  useEffect(() => {
    const membershipToken = localStorage.getItem("MToken");

    // Decode the membership token to get org details
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        const organisation_name =
          res.data.data.membership_data.organisation_name;

        // Directly set auditProgramHeader's org_id
        setAuditProgramHeader((prevState) => ({
          ...prevState,
          org_id: res.data.data.membership_data.org_id,
        }));

        
      })
      .catch((error) => {
        console.error("Error decoding membership token:", error);
      });
  }, []); // Empty dependency array

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleDurationChange = (e) => {
    setProgramDuration(parseInt(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const startDate = new Date(selectedYear, 0, 1);
      const endDate = new Date(selectedYear + programDuration - 1, 11, 31);

      const newAuditProgramHeader = {
        ...auditProgramHeader,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        is_active: true,
      };

      const response = await axios.post(`${apiUrl}/audit/generate_program_header`, newAuditProgramHeader);
      console.log(response.data);
      
    } catch (error) {
      console.error("Error creating audit program:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuditProgramHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg">
        <div>
          <label className="text-lg mb-2">Program Name:</label>
          <input
            type="text"
            name="program_name"
            value={auditProgramHeader.program_name}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>
        <div>
          <label className="text-lg mb-2">Start Year:</label>
          <input
            type="number"
            name="start_year"
            value={selectedYear}
            onChange={handleYearChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>
        <div>
          <label className="text-lg mb-2">Program Duration:</label>
          <div>
            {[1, 2, 3].map((duration) => (
              <div key={duration} className="flex items-center">
                <input
                  type="radio"
                  id={`duration${duration}`}
                  name="program_duration"
                  value={duration}
                  checked={programDuration === duration}
                  onChange={handleDurationChange}
                  className="mr-2"
                />
                <label htmlFor={`duration${duration}`}>{duration} Year{duration > 1 && "s"}</label>
              </div>
            ))}
          </div>
        </div>
        <button type="submit" className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto">
          Create Program
        </button>
      </form>
    </div>
  );
};

export default AuditProgramForm;
