import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Logo from "./logo.png";
import defaultProfilePic from "./profile_default.jpg"; // Update the path to match your actual file location
import { ThemeContext } from "./theme.js";
import { StyledButton, StyledNavLink, StyledDiv } from "./styledComponents.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPersonThroughWindow,
  faRightFromBracket,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

function Home() {
  const [userID, setUserID] = useState("");
  const [profile, setProfile] = useState(null);
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [logoUrl, setLogoUrl] = useState(Logo);
  const [userPicUrl, setUserPicUrl] = useState(null);
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);
  const mToken = localStorage.getItem("MToken");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [membershipIsExpanded, setMembershipIsExpanded] = useState(false);

  const toggleMemberships = () => {
    setMembershipIsExpanded(!membershipIsExpanded);
  };

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;

      setUserID(userID);

      fetchUserProfile(userID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const fetchUserProfile = async (userID) => {
    try {
      const userProfileRes = await axios.get(apiUrl + `/profiles/${userID}`);
      setProfile(userProfileRes.data);
      console.log(userProfileRes.data);

      // Check if the user has a profile picture URL
      if (!userProfileRes.data.profile) {
        // If there is no profile picture, use the default profile picture URL
        const defaultProfilePicURL = defaultProfilePic;
        setUserPicUrl(defaultProfilePicURL);
      } else {
        // User has a profile picture, fetch and set it
        try {
          const res = await axios.get(
            apiUrl +
              `/profiles/retrieve_file/${userID}/${userProfileRes.data.profile}`
          );
          const profilePicUrl = res.data.url;
          setUserPicUrl(profilePicUrl);
        } catch (err) {
          console.error(err);
        }
      }
    } catch (error) {
      console.error("There was an error fetching the profile!", error.response);
    }
  };

  const handleUploadProfilePicture = async () => {
    if (selectedFile) {
      // Create a FormData object to send the selected file
      const formData = new FormData();
      formData.append("profile_pic", selectedFile);
      console.log(selectedFile);
      try {
        // Call the API endpoint to upload the profile picture
        await axios.put(
          apiUrl + `/profiles/${userID}/profile_picture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle success, e.g., show a success message
        console.log("Profile picture uploaded successfully!");
        setUserPicUrl(URL.createObjectURL(selectedFile));
      } catch (error) {
        // Handle error, e.g., show an error message
        console.error("Error uploading profile picture:", error);
      }
    } else {
      // Handle the case where no file is selected
      console.error("No file selected for upload.");
    }
  };

  const handleFileInputChange = (e) => {
    // Handle the file input change event and set the selected file
    const file = e.target.files[0];
    setSelectedFile(file);

    // Automatically trigger the profile picture upload when a file is selected
    handleUploadProfilePicture();
  };

  const fetchMemberships = async (userID) => {
    try {
      const membershipsRes = await axios.get(
        apiUrl + `/user-memberships/${userID}`
      );
      const memberships = membershipsRes.data;
      console.log("Memberships:", memberships);
      // Group memberships by organization
      const organizations = memberships.reduce((acc, membership) => {
        if (!acc[membership.org_id]) {
          acc[membership.org_id] = {
            organisation_name: membership.organisation_name,
            org_id: membership.org_id,
            memberships: [],
          };
        }
        acc[membership.org_id].memberships.push({
          role: membership.role_id,
          function: membership.function_id,
         
        });
        return acc;
      }, {});

      // Convert the object to an array
      const uniqueOrganizations = Object.values(organizations);

      setMemberships(uniqueOrganizations);
    } catch (error) {
      console.error("There was an error fetching the memberships!", error);
    }
  };

  const handleMembershipClick = async (membership) => {
    setSelectedMembership(membership);
    console.log("Selected Membership:", membership);

    try {
      const token = localStorage.getItem("access_token");
      // Extract org_id from membership.data
      const orgId = membership.org_id;

      const membershipTokenRes = await axios.post(
        `${apiUrl}/membership-token`,
        {
          membership_data: membership,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      localStorage.setItem("MToken", membershipTokenRes.data.membership_token);

      const organisationRes = await axios.get(
        `${apiUrl}/organisations/${orgId}`
      );

      const {
        primary_colour,
        accent_colour,
        primary_text_and_icon_colour,
        secondary_text_and_icon_colour,
      } = organisationRes.data;

      // Retrieve the logo URL
      try {
        const logoRes = await axios.get(
          `${apiUrl}/organisations/retrieve_file/${orgId}/logo.png`
        );
        const newLogoUrl = logoRes.data.url;

        // Set the theme with the new logo URL
        setTheme({
          primary_colour: primary_colour,
          accent_colour: accent_colour,
          primary_text_and_icon_colour: primary_text_and_icon_colour,
          secondary_text_and_icon_colour: secondary_text_and_icon_colour,
          logoUrl: newLogoUrl, // Set the logo URL in the theme
        });
      } catch (err) {
        console.error("There was an error retrieving the logo:", err);
      }
    } catch (error) {
      console.error(
        "There was an error creating the membership token!",
        error.response
      );
    }
  };

  useEffect(() => {
    fetchUserID();
    fetchMemberships(userID);
    fetchUserProfile(userID, userPicUrl);
  }, [userID, userPicUrl]);

  if (!profile) return "Loading.."; // Add some loading state handling

  return (
    <div className="flex">
      <div className="flex flex-col items-center w-1/4 mt-5">
        {" "}
        {/* Adjust width as needed */}
        <img
          src={theme.logoUrl}
          alt="Membership Logo"
          className="h-16
         w-auto mb-5"
        />
        <StyledNavLink
          primary
          theme={theme}
          className="w-1/2 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-center"
          onClick={toggleMemberships}
        >
          Memberships
        </StyledNavLink>
        {profile.is_superAdmin && (
          <StyledNavLink
            primary
            theme={theme}
            to="/admin"
            className="w-1/2 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-center"
          >
            Admin
          </StyledNavLink>
        )}
        {mToken && (
          <StyledNavLink
            primary
            theme={theme}
            to="/audit/home"
            className="w-1/2 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-center"
          >
            Audit
          </StyledNavLink>
        )}
      </div>

      <div className="flex flex-col w-3/4 pl-4">
        {" "}
        {/* Adjust width as needed */}
        <div className="flex justify-between items-center mt-3">
          <h2 className="font-bold text-2xl">Welcome {profile.first_name}!</h2>

          <div className="relative">
            <img
              src={userPicUrl}
              alt="Profile"
              style={{
                width: "75px",
                height: "75px",
                marginRight: "20px",
                marginTop: "10px",
                borderRadius: "50%",
                objectFit: "cover",
                zIndex: 30, // Ensure the profile picture is in front
                position: "relative",
              }}
              onMouseEnter={() => setShowProfileMenu(true)}
              onMouseLeave={() => setShowProfileMenu(false)}
            />
            {showProfileMenu && (
              <div
                className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"
                style={{ top: "65px" }} // Adjust the top value to overlap the profile picture
                onMouseEnter={() => setShowProfileMenu(true)}
                onMouseLeave={() => setShowProfileMenu(false)}
              >
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    // Open the file input dialog when the edit icon is clicked
                    const fileInput = document.createElement("input");
                    fileInput.type = "file";
                    fileInput.accept = "image/jpeg, image/png";
                    fileInput.onchange = handleFileInputChange;
                    fileInput.click();
                  }}
                >
                  <FontAwesomeIcon icon={faUserPen} size="sm" color="grey" />{" "}
                  Update Profile
                </div>
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    localStorage.removeItem("access_token");
                    navigate("/login");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    size="sm"
                    color="grey"
                  />{" "}
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: theme.accent_colour,
            margin: "16px 0",
          }}
        ></div>
        <div>
          {membershipIsExpanded && (
            <div>
             <h3 className="font-bold text-xl mt-4 mb-2">Memberships</h3>
<div className="grid grid-cols-3 gap-4 pr-4">
  {memberships.map((organization, index) => (
    <StyledDiv
      key={index}
      className={`p-6 rounded text-left ${
        selectedMembership === organization ? "selected" : ""
      }`}
      onClick={() => handleMembershipClick(organization)}
      style={{
        backgroundColor:
          selectedMembership === organization
            ? theme.primary_colour
            : "transparent",
        color:
          selectedMembership === organization
            ? theme.primary_text_and_icon_colour
            : "inherit",
      }}
    >
      <h4 className="font-bold">{organization.organisation_name}</h4>
      
    </StyledDiv>
  ))}
</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
