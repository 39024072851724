// React and React-related imports
import React, { useState, useEffect, useContext } from "react";

// Third-party libraries
import axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "lodash";

// Router imports
import { useNavigate, useParams } from "react-router-dom";

// Context imports
import { ThemeContext } from "./theme.js";
// Icon imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faHomeLgAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

// CSS and Style imports

import "./styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Styled-components and Related Imports
import { ThemeProvider } from "styled-components";
import {
  GlobalStyle,
  StyledCarousel,
  BottomContainer,
  AddButton,
  NoChecklistsMessage,
  ChecklistsGrid,
  AuditHeader,
  AuditDetails,
  StyledNavLink,
} from "./styledComponents";

// Component imports
import auditProtection from "./HOC/auditProtection";
import CircularProgress from "./CircularProgress";
import CircularScore from "./CircularScore";

const apiUrl = process.env.REACT_APP_API_URL;

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
}

function CreateAuditPage(props) {
  const { theme, setTheme } = useContext(ThemeContext);
  const [userID, setUserID] = useState("");
  const [checklists, setChecklists] = useState([]);
  const navigate = useNavigate();
  const { auditId } = useParams();
  const [isAdding, setIsAdding] = useState(false); 
  const [addedChecklists, setAddedChecklists] = useState([]);
  const [auditDetails, setAuditDetails] = useState(null);
  const [progress, setProgress] = useState({ total: 0, answered: 0 });
  const [showBottomContainer, setShowBottomContainer] = useState(false);
  const [availableBlueprints, setAvailableBlueprints] = useState([]);

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;
      setUserID(userID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const fetchAuditDetails = async (auditId) => {
    try {
      // Call the function to update the status on the server
      if (auditId) {
        await updateAuditStatusOnServer(auditId);
      }

      const response = await axios.get(
        `${apiUrl}/audit/get_audit_header/${auditId}`
      );
      const auditDetailsData = response.data;
      console.log("Audit details:", auditDetailsData);
      // Set the audit details in the state
      setAuditDetails(auditDetailsData);
    } catch (error) {
      console.error("Error fetching audit details:", error);
      // Handle error appropriately
    }
  };

  const fetchChecklists = async (auditId) => {
    try {
        // Fetch checklists already in the audit
        const responseAuditChecklists = await axios.get(
            `${apiUrl}/audit/get_audit_checklists/${auditId}`
        );

        const auditChecklists = responseAuditChecklists.data.checklist_names; // Updated property name
        console.log(
            "Audit checklists:",
            responseAuditChecklists.data.checklist_names
        );

        // Process the audit checklists
        const formattedChecklists = auditChecklists.map((checklist) => ({
            id: checklist.id, // Add the id property
            checklist_name: checklist.checklist_name,
            created_at: checklist.created_at,
            updated_at:
                checklist.updated_at !== undefined ? checklist.updated_at : null,
            // Add other checklist details as needed
        }));

        setAddedChecklists(formattedChecklists);

        // Fetch organization ID from decoded membership token
        const mToken = localStorage.getItem("MToken");
        if (!mToken) {
            navigate("/audit/home"); // Redirect to home if there is no MToken
            return;
        }

        const response = await axios.post(
            `${apiUrl}/decode-membership-token/${mToken}`
        );
        const membershipData = response.data;

        if (
            membershipData &&
            membershipData.data &&
            membershipData.data.membership_data
        ) {
            const orgId = membershipData.data.membership_data.org_id;
            console.log("Organization ID:", orgId);

            // Fetch available blueprints for the organization
            const responseAvailableBlueprints = await axios.get(
                `${apiUrl}/audit/get_available_blueprints/${orgId}`
            );

            const availableBlueprints = responseAvailableBlueprints.data;

            setAvailableBlueprints(availableBlueprints);
        }
    } catch (error) {
        console.error("Error fetching audit checklists:", error);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await axios.get(`${apiUrl}/audit/progress/${auditId}`);
      setProgress(response.data);
    } catch (error) {
      console.error("Error fetching progress:", error);
      // Handle error appropriately
    }
  };

  const AuditScores = ({ auditId }) => {
    const [score, setScore] = useState(null);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/audit/scores/audit/${auditId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      if (auditId) {
        fetchScore();
      }
    }, [auditId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div className="circular-score-container">
        <CircularScore averageScore={score} />{" "}
        {/* Make sure prop names match */}
      </div>
    );
  };

  const ChecklistScores = ({ checklistId, auditId }) => {
    const [score, setScore] = useState(null);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/audit/scores/checklist/${checklistId}/audit/${auditId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      if (checklistId && auditId) {
        fetchScore();
      }
    }, [checklistId, auditId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div className="circular-score-container">
        <CircularScore averageScore={score} />{" "}
        {/* Make sure prop names match */}
      </div>
    );
  };

  const updateAuditStatusOnServer = async (auditId) => {
    try {
      await axios.post(`${apiUrl}/audit/update_audit_status/${auditId}`);
    } catch (error) {
      console.error("Error updating audit status:", error);
      // Handle error appropriately
    }
  };

  const handleDownloadReport = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/audit/generate_audit_report/${auditId}`,
      {
        responseType: "blob", // Important: handle the response as a Blob
      }
    );

    // Create a URL for the blob
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", `audit_report_${auditId}.pdf`);
    document.body.appendChild(fileLink);

    // Programmatically click the link to trigger the download
    fileLink.click();

    // Clean up: remove the link
    fileLink.parentNode.removeChild(fileLink);
  } catch (error) {
    console.error("Error generating audit report: ", error);
    Swal.fire({
      title: "Error!",
      text: "Failed to generate audit report.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
  };

  const handleDeleteChecklist = async (checklistId, auditId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/audit/checklist_answers_exist/${checklistId}/${auditId}`
      );
      const hasAnswers = response.data.hasAnswers;

      if (hasAnswers) {
        Swal.fire({
          title: `This checklist cannot be deleted because it has questions with answers.`,
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Hold on a sec!",
          text: "Are you sure you want to delete this checklist?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "DELETE CHECKLIST",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Type 'DELETE' to confirm",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            if (input !== "DELETE") {
              Swal.showValidationMessage(
                "You need to type 'DELETE' to confirm"
              );
            } else {
              try {
                await deleteChecklist(checklistId, auditId);
                fetchChecklists(auditId);
              } catch (error) {
                console.error("Error deleting checklist:", error);
                // Handle error appropriately
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    } catch (error) {
      console.error("Error handling audit deletion:", error);
      // Handle error appropriately
    }
  };

  const deleteChecklist = async (checklistId) => {
    try {
      await axios.delete(
        `${apiUrl}/audit/delete_audit_checklist/${checklistId}/${auditId}`
      );
      fetchChecklists(auditId);
    } catch (error) {
      console.error("Error deleting checklist:", error);
      // Handle error appropriately
    }
  };

  const handleAddToAudit = async (checklistId) => {
    if (isAdding) return;
  
    setIsAdding(true);
    
    try {
      const response = await axios.post(`${apiUrl}/audit/add_audit_checklist`, {
        auditHeaderId: auditId,
        checklist_header_id: checklistId,
      });
  
      // Assuming fetchChecklists updates the state after fetching
      await fetchChecklists(auditId);
    } catch (error) {
      console.error("Error adding audit detail:", error);
    } finally {
      setIsAdding(false);
    }
  };

  const renderAddedChecklists = () => {
    console.log("Added checklists:", addedChecklists);
    
    return addedChecklists.map((checklist) => (
      <div
        key={checklist.id}
        className="border p-4 rounded-lg shadow-md relative"
      >
      
        {/* Title and Trash Icon */}
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold">{checklist.checklist_name}</h3>
          <button
            className="text-red-400"
            onClick={() => handleDeleteChecklist(checklist.id, auditId)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {/*<p>Checklist ID: {checklist.id}</p>*/}
        <p>Date Added: {formatDate(checklist.created_at)}</p>
        {checklist.updated_at && (
          <p>Updated On: {formatDate(checklist.updated_at)}</p>
        )}

        {/* Scores */}
        <div className="flex justify-end mt-auto">
          <ChecklistScores checklistId={checklist.id} auditId={auditId} />
        </div>
      </div>
    ));
  };

  const closeAudit = async () => {
    try {
      await axios.post(`${apiUrl}/audit/close_audit/${auditId}`);
      fetchAuditDetails(auditId);
    } catch (error) {
      console.error("Error closing audit:", error);
      // Handle error appropriately
    }
  };

  const handleToggleBottomContainer = () => {
    setShowBottomContainer(!showBottomContainer);
  };

  const handleAddSummary = () => {
    Swal.fire({
      title: "Audit Summary",
      html: `
        <textarea id="swal-input" class="swal2-textarea" placeholder="Type your summary here..." style="width: 60vh; height: 30vh;">${
          auditDetails && auditDetails.summary !== null ? auditDetails.summary : ""
        }</textarea>
      `,
      focusConfirm: false,
      width: "75vh",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: theme.primary_colour,
      cancelButtonText: "Close",
      didOpen: () => {
        const textarea = document.getElementById("swal-input");
        textarea.addEventListener(
          "input",
          debounce((event) => {
            handleCommentChange(auditDetails.id, event.target.value);
          }, 500)
        );
        textarea.focus();
        const textLength = textarea.value.length;
        textarea.setSelectionRange(textLength, textLength);
      },
      willClose: () => {
        fetchAuditDetails(auditId);
      },
    });
  };
  
  const handleCommentChange = debounce(async (auditContentId, summary) => {
    try {
      const response = await axios.post(`${apiUrl}/audit/update_summary`, {
        id: auditContentId,
        summary,
      });
  
      // Check if response data is null and handle accordingly
      if (response.data && response.data.message) {
        console.log(response.data.message);
      } else {
        console.log("Summary updated successfully.");
      }
    } catch (error) {
      console.error("Error updating comment", error);
    }
  }, 500);
  
  useEffect(() => {
    if (auditId) {
      // Fetch audit details and progress
      fetchAuditDetails(auditId);
      fetchProgress();
  
      // Fetch checklists and render added checklists
      fetchChecklists(auditId).then(() => {
        // After fetching checklists, render added checklists
        renderAddedChecklists();
      });
    }
  }, [auditId]);
  
  useEffect(() => {
    // Render added checklists whenever addedChecklists state changes
    renderAddedChecklists();
  }, [addedChecklists]);
  

  //Main Render
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <div className="container mx-auto px-6">
          <div className="flex items-center justify-between mt-5 mb-10">
            <div
              onClick={() => navigate("/audit/home")}
              className="text-primary cursor-pointer flex-shrink-0"
            >
              {theme.logoUrl ? (
                <img
                  src={theme.logoUrl}
                  alt="Membership Logo"
                  className="h-16 w-auto"
                />
              ) : (
                <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
              )}
            </div>
            {auditDetails && (
              <h1 className="text-4xl text-primary flex-grow text-center">
                {auditDetails.audit_name}
              </h1>
            )}

            <div className="w-20">
              {" "}
              {/* Placeholder to balance the header */}
            </div>
          </div>
          <AuditHeader>
            {auditDetails && (
              <AuditDetails>
                <p>
                  <strong>Audit Type:</strong> {auditDetails.audit_type}
                </p>
                <p>
                  <strong>Auditor:</strong> {auditDetails.auditor}
                </p>
                <p>
                  <strong>Auditee:</strong> {auditDetails.auditee_name}
                </p>
                <p>
                  <strong>Date Scheduled:</strong>{" "}
                  {formatDate(auditDetails.date_scheduled)}
                </p>
                <p>
                  <strong>Status: </strong>
                  {auditDetails && auditDetails.status === "Closed" ? (
                    <span>
                      Closed
                      <FontAwesomeIcon
                        icon={faLock}
                        className="ml-2"
                        style={{ color: "grey" }}
                      />
                    </span>
                  ) : (
                    auditDetails.status
                  )}
                </p>

                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    total={progress.total}
                    answered={progress.answered}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <AuditScores auditId={auditId} />
                  </div>
                </div>
              </AuditDetails>
            )}
          </AuditHeader>

          <div className="flex">
            {/* Sidebar for buttons */}
            <div className="w-1/5">
              {" "}
              {/* Sidebar with 1/5 width */}
              <StyledNavLink
                primary
                theme={theme}
                disabled={auditDetails ? auditDetails.status == "Closed" : true}
                onClick={handleToggleBottomContainer}
                className="w-full mx-auto block  mb-3 py-2 px-4 rounded-lg text-center"
              >
                {showBottomContainer ? "Hide BluePrint Menu" : "Add Blueprints"}
                {auditDetails && auditDetails.status == "Closed" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                disabled={auditDetails ? auditDetails.status == "Closed" : true}
                onClick={handleAddSummary}
                className="w-full mx-auto block mt-3 mb-3 py-2 px-4 rounded-lg text-center"
              >
                Audit Summary
                {auditDetails && auditDetails.status == "Closed" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                disabled={auditDetails ? auditDetails.status == "Closed" : true}
                to={`/audit/audit_live/${auditId}`}
                className="w-full mx-auto block mt-3 mb-3 py-2 px-4 rounded-lg text-center"
              >
                Perform Audit
                {auditDetails && auditDetails.status == "Closed" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails ? auditDetails.status !== "Complete" : true
                }
                className="w-full mx-auto block mt-3 mb-3 py-2 px-4 rounded-lg text-center"
                onClick={closeAudit} // Attach the close audit function here
              >
                Close Audit
                {auditDetails && auditDetails.status !== "Complete" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                onClick={handleDownloadReport} // Attach the download function here
                className="w-full mx-auto block mt-3 mb-3 py-2 px-4 rounded-lg text-center"
                style={{ cursor: "pointer" }} // Add cursor style for better UX
              >
                Generate Audit Report
              </StyledNavLink>
            </div>
            <div className="w-4/5 pl-4">
              {" "}
              {/* Main content with 4/5 width */}
              <ChecklistsGrid>{renderAddedChecklists()}</ChecklistsGrid>
            </div>
          </div>
        </div>

        {showBottomContainer && (
          <BottomContainer>
            {/* Ensure availableBlueprints is defined and has a length property */}
            {availableBlueprints && availableBlueprints.length > 0 ? (
              <StyledCarousel
                showThumbs={false}
                emulateTouch={true}
                theme={theme}
                centerMode={true}
                centerSlidePercentage={100 / 5}
              >
                {/* Map through availableBlueprints if it's an array */}
                {availableBlueprints.map((blueprint) => (
                  <div key={blueprint[2]}>
                    <div style={{ textAlign: "center" }}>
                      <h3>{blueprint[3]}</h3>
                      
                    </div>
                    <AddButton
                      onClick={() => handleAddToAudit(blueprint[2])}
                      disabled={isAdding}

                      
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </AddButton>
                  </div>
                ))}
              </StyledCarousel>
            ) : (
              <NoChecklistsMessage>
                No blueprints available to add.
              </NoChecklistsMessage>
            )}
          </BottomContainer>
        )}
      </ThemeProvider>
    </>
  );
}

export default auditProtection(CreateAuditPage);
