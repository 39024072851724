import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Logo from './logo.png';
import "./styles.css";

const apiUrl = process.env.REACT_APP_API_URL;

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const register = () => {
        
    axios({
      method: 'post',
      url: apiUrl + "/global_profile",
      data: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        password: password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      console.log(response);
      navigate("/");
    })
    .catch(error => {
      console.error('There was an error!', error.response);
      alert("Failed to register new user");
  });
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen">

      <div className="flex justify-center w-full p-4">
          <img src={Logo} alt="Logo" className="navbar-item logo-image-size mb-8" />
      </div>

        <h2 className="text-4xl text-center mb-10 text-primary">Create Your Profile</h2>

        <div className="flex flex-col w-4/5 md:w-1/2 lg:w-1/3">

            <label className="text-xl mb-2 text-primary">First Name</label>
            <div className="flex border-2 rounded px-4 py-2 mb-4 items-center"
                onChange={(e) => setFirstName(e.target.value)}>
                <input className="flex-1 outline-none" type="text" placeholder="First Name" />
                <span>
                    <i className="fas fa-user text-gray-400"></i>
                </span>
            </div>
            <label className="text-xl mb-2 text-primary">Last Name</label>
              <div className="flex border-2 rounded px-4 py-2 mb-4 items-center"
                  onChange={(e) => setLastName(e.target.value)}>
                <input className="flex-1 outline-none" type="text" placeholder="Last Name" />
                <span>
                  <i className="fas fa-user text-gray-400"></i>
                </span>
              </div>

                  <label className="text-xl mb-2 text-primary">Email</label>
                  <div className="flex border-2 rounded px-4 py-2 mb-4 items-center"
                      onChange={(e) => setEmail(e.target.value)}>
                    <input className="flex-1 outline-none" type="email" placeholder="Email" />
                    <span>
                      <i className="fas fa-envelope text-gray-400"></i>
                    </span>
                  </div>
                  <label className="text-xl mb-2 text-primary">Phone</label>
                    <div className="flex border-2 rounded px-4 py-2 mb-4 items-center"
                        onChange={(e) => setPhone(e.target.value)}>
                      <input className="flex-1 outline-none" type="tel" placeholder="Phone Number"/>
                      <span>
                        <i className="fas fa-phone text-gray-400"></i>
                      </span>
                    </div>

                    <label className="text-xl mb-2 text-primary">Password</label>
                    <div className="flex border-2 rounded px-4 py-2 mb-4 items-center"
                        onChange={(e) => setPassword(e.target.value)}>
                      <input className="flex-1 outline-none" type="password" placeholder="Password"/>
                      <span>
                        <i className="fas fa-lock text-gray-400"></i>
                      </span>
                    </div>

                  <div className="flex justify-center mt-6">
                    <button onClick={register} className="bg-primary w-4/5 py-2 px-4 rounded-full text-white hover:bg-secondary">
                      Register
                    </button>
               </div>
               <div className="mt-4 text-center">
          <Link className="text-xs text-primary" to="/login">
            Have an account already? Login here
          </Link>
        </div>
          </div>
      </div>
   )
}

export default Register;