import React, { useState, useEffect } from 'react';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_URL;

const StatusPage = () => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    // Fetch the status from the backend API
    axios.get(apiUrl + "/status")
      .then(response => {
        setStatus(response.data.status);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching status:", error);
        setStatus('Error');
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <p>Status: {status} API-URL: {apiUrl}</p>
      )}
    </div>
  );
};

export default StatusPage;
